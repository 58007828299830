import React from "react";
import MenuButton from "./elements/MenuButton";
import { useDispatch, useSelector } from "react-redux";
import { toggleMusic, toggleSound } from "../store/sound";

const SettingsPage = () => {

    const dispatch = useDispatch();
    const settings = useSelector(state => state.sound.settings);

    const toggleMusicSetting = () => {
        dispatch(toggleMusic());
    }

    const toggleSoundSetting = () => {
        dispatch(toggleSound());
    }

    return (
        <div className="e-flex e-flex_col e-flex_center SettingsPage">
            <MenuButton title={`Музыка ${(settings.music) ? "вкл." : "выкл."}`} onClick={toggleMusicSetting} />
            <MenuButton title={`Звуки ${(settings.sound) ? "вкл." : "выкл."}`} onClick={toggleSoundSetting} />
        </div>
    );
}

export default SettingsPage;