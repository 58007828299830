import React from "react";

const TableItem = (props) => {

    const calcTime = (timer) => {
        const minutes = Math.floor((timer % 3600000) / 60000); // Минуты
        const seconds = Math.floor((timer % 60000) / 1000); // Секунды
        const ms = Math.floor((timer % 1000) / 10); // Килосекунды

        // Форматирование с добавлением ведущих нулей
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        const formattedMs = String(ms).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}:${formattedMs}`;
    };

    return (
        <tr>
            <td>{props.id + 1}</td>
            <td>{props.user.name}</td>
            {/* <td>{props.user.phone}</td> */}
            <td>{props.user.level}</td>
            <td>{calcTime(props.user.avg_timer)}</td>
        </tr>
    )
}

export default TableItem;