import React, { useEffect, useState } from "react";
import MenuButton from "./elements/MenuButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const LevelsPage = () => {

    const params = useParams();
    const mode = params.mode;
    const [levels, setLevels] = useState(null);
    const [lastLevel, setLastLevel] = useState(0);
    const userData = useSelector(state => state.main.userData);

    // Вычисляем последний пройденный уровень
    useEffect(() => {
        const getLastLevel = () => {
            let lastLevel = 0;
            if (userData.games) {
                userData.games.forEach(level => {
                    if (parseInt(level.level) > lastLevel) {
                        lastLevel = parseInt(level.level);
                    }
                });
            }
            return lastLevel;
        }

        setLastLevel(getLastLevel());

        getLevels(mode);

    }, [userData]);

    // Форматирование таймера уровня
    const calcTime = (timer) => {
        const minutes = Math.floor((timer % 3600000) / 60000); // Минуты
        const seconds = Math.floor((timer % 60000) / 1000); // Секунды
        const ms = Math.floor((timer % 1000) / 10); // Килосекунды
    
        // Форматирование с добавлением ведущих нулей
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        const formattedMs = String(ms).padStart(2, '0');
    
        return `${formattedMinutes}:${formattedSeconds}:${formattedMs}`;
    };    


    const getLevels = async (mode) => {
        let range = [];
        let levels = [];
        switch (mode) {
            case "easy": range = [1, 35]; break;
            // case "medium": range = [16, 25]; break;
            // case "hard": range = [26, 35]; break;
            default: break;
        }
        for (let i = range[0]; i <= range[1]; i++) {
            if (userData.games.find(lvl => +lvl.level == i)) {
                try {
                    const lvl = await fetch(`/levels/${i}.json`)
                        .then(data => data.json())
                        .then(data => {
                            const timer = userData.games.find(lvl => +lvl.level == i).timer;
                            data.timer = calcTime(timer);
                            levels.push(data);
                        })
                        .catch(err => console.log(err));
                } catch (err) {
                    console.log(err)
                }
            } else {
                try {
                    const lvl = await fetch(`/levels/${i}.json`)
                        .then(data => data.json())
                        .then(data => {
                            // const timer = userData.games.find(lvl => +lvl.level == i).timer;
                            // data.timer = calcTime(timer);
                            levels.push(data);
                        })
                        .catch(err => console.log(err));
                } catch (err) {
                    console.log(err)
                }
                break;
            }
        }
        setLevels(levels);
    }

    useEffect(() => {
        if (mode) {
            getLevels(mode);
        }
    }, [mode]);

    // Проверяем выбрана ли сложность, если нет предлагаем выбрать
    return (!mode) ? (
        <div className="e-flex e-flex_col e-flex_center ModePage">
            <MenuButton title="Продолжить" desc={`${lastLevel + 1} уровень`} link={`/game/${lastLevel + 1}`} />
            <MenuButton title="К уровням" link="/map/easy" />
            {/* { (lastLevel >= 15) && <MenuButton title="Средняя" link="/map/medium" />}
            { (lastLevel >= 25) && <MenuButton title="Сложная" link="/map/hard" /> } */}
        </div>
    ) : (
        <div className="e-flex e-flex_col e-flex_ac LevelsPage">
            {
                (Array.isArray(levels)) && levels.map((lvl) => {
                    return (
                        <MenuButton key={lvl.level} title={"Уровень " + lvl.level} desc={lvl.timer} link={"/game/" + lvl.level} />
                    )
                })
            }
        </div>
    )
}

export default LevelsPage;