import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserData } from "../store";

export const tokenVerify = createAsyncThunk("main/tokenVerify", async ({ token }, ThunkAPI) => {
    try {
        const response = await fetch('https://grohe.promo/api.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            console.error("Ошибка сети при верификации токена");
            return { success: false, message: "Ошибка сети" };
        }

        const data = await response.json();
        if (!data.success) {
            console.log("Неверный токен!");
            return { success: false, message: "Неверный токен" };
        }

        console.log("Токен валидный!");
        return { success: true, data };
    } catch (error) {
        console.error("Ошибка верификации токена:", error);
        return { success: false, message: "Ошибка верификации" };
    }
});


export const fetchUserData = createAsyncThunk("main/fetchUserData", async ({ username }, ThunkAPI) => {
    const state = ThunkAPI.getState();
    const userData = state.main.userData;
    fetch('https://grohe.promo/api.php?phone=' + username, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.main.userData.token}`,
        },
        body: JSON.stringify({}),
    })
        .then((data) => data.json())
        .then((response) => {
            ThunkAPI.dispatch(setUserData({ ...userData, ...response }))
        });
});

export const checkPromo = createAsyncThunk("main/checkPromo", async (_, ThunkAPI) => {
    const state = ThunkAPI.getState();
    const userData = state.main.userData;
    try {
    const response = await fetch(`https://grohe.promo/api.php?phone=${userData.username}&promo=yes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userData.token}`,
        }
    })
    
    if (!response.ok) {
        console.error("Ошибка сети при выдаче промокода");
        return { success: false, message: "Ошибка сети" };
    }
    const data = await response.json();
    console.log(data);

    return { success: true, data };

} catch (error) {
    console.log("Ошибка при запросе промокода");
    return { success: false, message: "Ошибка: " + error };
}

});

export const fetchStats = createAsyncThunk("main/fetchStats", async (_, ThunkAPI) => {
    const state = ThunkAPI.getState();
    const userData = state.main.userData;
    try {
        const response = await fetch('https://grohe.promo/api.php?top=yes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.main.userData.token}`,
            },
            body: JSON.stringify({}),
        })

        if (!response.ok) {
            console.error("Ошибка сети при выдаче промокода");
            return { success: false, message: "Ошибка сети" };
        }
        const data = await response.json();
        console.log(data);
    
        return { success: true, data: data.top };
        
    } catch (error) {
        console.log("Ошибка при запросе статистики");
        return { success: false, message: "Ошибка: " + error };
    }

});