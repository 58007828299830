import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserData } from "./fetchData";

export const sendWin = createAsyncThunk("main/sendWin", async ({ level, timer }, ThunkAPI) => {
    const state = ThunkAPI.getState();
    fetch('https://grohe.promo/api.php?phone=' + state.main.userData.username + '&level=' + level + '&timer=' + timer, {
        headers: {
            'Authorization': `Bearer ${state.main.userData.token}`,
        }
    })
        .then((data) => data.json())
        .then((data) => {
            ThunkAPI.dispatch(fetchUserData({username: state.main.userData.username}));
        });
});