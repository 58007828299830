import React, { useEffect, useRef, useState } from 'react';
import Tile from './Tile'; // Импортируем компонент Tile
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLevel, setWin, setTimer } from '../../store/store';
import { sendWin } from '../../store/thunk/sendData';

// Сетки для уровней теперь делаем в папке: /public/levels/номер_уровня.json
// И смотрим по ссылке: http://localhost:3000/game/номер_уровня

// Типы труб:
// 0: Пустая ячейка
// 1: Прямая труба
// 2: Поворот 90
// 3: Т-образная
// 4: Крестообразная (плюсик)
// 5: Крестообразная (пересечение с зеленой)
// 6: Крестообразная (пересечение с синей)
// 7: Крестообразная (пересечение с фиолетовой)
// 8: Крестообразная (пересечение с красной)
// 9: Ничего
// 10: Начало/конец трубы
// 11: Двойной поворот (пересечение с зеленой)
// 12: Двойной поворот (пересечение с синей)
// 13: Двойной поворот (пересечение с фиолетовой)
// 14: Двойной поворот (пересечение с красной)

const PipeGame = () => {

  const params = useParams();
  const level = params.id;
  const dispatch = useDispatch();
  const [grid, setGrid] = useState(null);
  const [winGrid, setWinGrid] = useState(null);
  const win = useSelector(state => state.main.game.win);
  const [timer, setLocalTimer] = useState(0);
  const timerRef = useRef(null);
  const timerTimeoutRef = useRef(null);

  // Инициализация таймера
  useEffect(() => {
    return () => {
      // clearTimeout(timerTimeoutRef.current); // Очищаем таймаут
      clearInterval(timerRef.current); // Очищаем интервал
    };
  }, []);

  useEffect(() => {
    if (win) {
      clearTimeout(timerTimeoutRef.current);
      clearInterval(timerRef.current);
    }
  }, [win])

  // Загружаем уровень
  useEffect(() => {
    setGrid(null);
    setLocalTimer(0);
    clearTimeout(timerTimeoutRef.current);
    clearInterval(timerRef.current);

    const startTimer = () => {
      timerRef.current = setInterval(() => {
        setLocalTimer((prev) => prev + 40);
      }, 40);
    };

    // Запускаем таймер с задержкой 1 секунда
    timerTimeoutRef.current = setTimeout(startTimer, 1000);

    if (level) {
      fetch('/levels/' + level + '.json')
        .then(data => data.json())
        .then(lvl => {
          setGrid(lvl.initialGrid);
          setWinGrid(lvl.winGrid);
        });

      // Сохраняем в состояние текущий уровень
      dispatch(setLevel(level));
    }
  }, [level]);

  const checkWin = (grid, winGrid) => {
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid[i].length; j++) {
        // Нужно ли проверять этот тип трубы
        if (grid[i][j][0] === 0) continue;
        else if (grid[i][j][0] === 10) continue;
        else {
          // Сравниваем второй элемент (угол поворота) с элементами winGrid
          const currentAngle = grid[i][j][1] || 0; // Если нет угла, принимаем 0
          const winAngle = winGrid[i][j];

          // Если углы не совпадают, возвращаем false
          if (currentAngle !== winAngle) {
            return false;
          }
        }
      }
    }
    return true; // Если все углы совпадают, возвращаем true
  };

  // Функция для поворота трубы
  const rotatePipe = (row, col, deg) => {
    const newGrid = grid.map((r, i) =>
      r.map((c, j) => {
        if (i === row && j === col) {
          switch (c[0]) {
            case 0: return [c[0], 0, c[2]];
            case 1: return [c[0], Math.abs(deg % 360), c[2]];
            case 2: return [c[0], Math.abs(deg % 180), c[2]];
            case 3: return [c[0], Math.abs(deg % 360), c[2]];
            case 4: return [c[0], 0, c[2]];
            case 5: return [c[0], Math.abs(deg % 180), c[2]];
            case 6: return [c[0], Math.abs(deg % 180), c[2]];
            case 7: return [c[0], Math.abs(deg % 180), c[2]];
            case 8: return [c[0], Math.abs(deg % 180), c[2]];
            case 9: return [c[0], 0, c[2]];
            case 10: return c;
            case 11: return [c[0], Math.abs(deg % 360), c[2]];
            case 12: return [c[0], Math.abs(deg % 360), c[2]];
            case 13: return [c[0], Math.abs(deg % 360), c[2]];
            case 14: return [c[0], Math.abs(deg % 360), c[2]];
            default: return c;
          }
        }
        return c;
      })
    );
    setGrid(newGrid);

    // logRotation(newGrid[row][col], winGrid[row][col]);

    // Проверка на выигрыш уровня, анимация выигрыша
    if (checkWin(newGrid, winGrid)) {
      dispatch(setTimer(timer));
      dispatch(setWin(true));
      // Отправляем в БД информацию о пройденном уровне
      dispatch(sendWin({ level: level, timer: timer }));
    } else {
      dispatch(setWin(false));
    }
  };

  // Логи для создания уровней
  const logRotation = (initialValue, winValue) => {
    console.log("=============================");
    // Выводит в консоль поворот, чтобы делать уровни
    if ([1, 2, 3, 5, 6, 7, 8, 11, 12, 13, 14].includes(initialValue[0])) {
      console.log(`Тип трубы: ${initialValue[0]}`);
      console.log("Текущая позиция:");
      console.log(initialValue[1]);

      console.log("Указана для победы:")
      console.log(winValue)
    } else {
      console.log(initialValue[1]);
    }
  }

  // Форматирование таймера
  const calcTime = (timer) => {
    const minutes = Math.floor((timer % 3600000) / 60000); // Минуты
    const seconds = Math.floor((timer % 60000) / 1000); // Секунды
    const ms = Math.floor((timer % 1000) / 10); // Килосекунды

    // Форматирование с добавлением ведущих нулей
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMs = String(ms).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}:${formattedMs}`;
  };

  // Убираем ПКМ
  const onContextMenu = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <div className={`container game e-flex_center`} onContextMenu={onContextMenu}>
        {(grid) && grid.map((row, i) => (
          <div key={i} className={`row`}>
            {row.map((value, j) => (
              <Tile
                key={j}
                initialValue={value}
                onRotate={(deg) => rotatePipe(i, j, deg)}
              />
            ))}
          </div>
        ))}


        {(!grid) && "Уровень в работе"}
      </div>
      <h3 className='timer'>{calcTime(timer)}</h3>
    </>
  );
};

export default PipeGame;