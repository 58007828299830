import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Иконка слева
    const menuIcon = () => {
        switch (location.pathname.split("/")[1]) {
            case "": return (<Link to="https://grohe.promo/"><img src="/images/logo.png" className="logo" alt="menu-button"></img></Link>);
            case "game": return (<button onClick={() => navigate('/map')}><img src="/images/game/icons/return-arrow.svg" alt="menu-button"></img></button>);
            case "map": return (<button onClick={() => navigate('/')}><img src="/images/game/icons/return-arrow.svg" alt="menu-button"></img></button>);
            default: return (<Link onClick={() => navigate(-1)}><img src="/images/game/icons/return-arrow.svg" alt="menu-button"></img></Link>);
        }
    }

    // Иконка справа
    const secondIcon = () => {
        switch (location.pathname.split("/")[1]) {
            case "game": return (<button onClick={() => window.location.reload()}><img src="/images/game/icons/restart.svg" alt="restart"></img></button>);
            default: return (<Link to="https://grohe.promo/?p=lk"><img src="/images/game/icons/gift.png" alt="profile"></img></Link>);
        }
    }

    return (
        <header>
            <div className="container e-flex e-flex_jb">
                <div className="e-flex">
                    <div className="e-flex button">
                        {menuIcon()}
                    </div>
                </div>
                <div className="e-flex button">
                    {secondIcon()}
                </div>
                {/* <div className="e-flex e-flex_ac stat"><img src="/images/game/coins.png" height={50} alt="coin"></img> <p>{balance}</p></div> */}
            </div>
        </header>
    )
}

export default Header;