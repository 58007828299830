import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
    name: "sound",
    initialState: {
        settings: {
            music: true,
            sound: true
        },
        music: {
            src: ['https://gameapp.grohe.promo/music/Yes and No at the Same Time - half.cool.mp3', 'https://gameapp.grohe.promo/music/What%20I%20Said%20-%20Ryan%20McCaffrey_Go%20By%20Ocean.mp3'],
            volume: 0.3,
            loop: true,
            autoplay: true
        },
        button: {
            src: ['https://gameapp.grohe.promo/music/Button_sound_design_mechanical_tonal_thin_simple_high_press_and_release.mp3'],
            volume: 0.8,
            // autoplay: true
        },
        tile: {
            src: ['https://gameapp.grohe.promo/music/tile.mp3'],
            volume: 0.8,
            // autoplay: true
        },
        win: {
            src: ['https://gameapp.grohe.promo/music/game-win.mp3'],
            volume: 1,
            autoplay: true
        },
    },
    reducers: {
        toggleMusic: (state, action) => {
            state.settings.music = !state.settings.music;
        },
        toggleSound: (state, action) => {
            state.settings.sound = !state.settings.sound;
        },
    }
});

export const { toggleMusic, toggleSound } = soundSlice.actions;
export default soundSlice.reducer;