import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Layout from "./components/layout/Layout";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "./store/store";
import { fetchUserData, tokenVerify } from "./store/thunk/fetchData";
import { setMusic } from "./store/sound";
import { Howl } from "howler";

function App() {

  const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const sound = useSelector(state => state.sound);
  const musicSetting = useSelector(state => state.sound.settings.music);
  // Реф для объекта Howl
  const musicRef = useRef(null);

  // Состояние для отслеживания, было ли взаимодействие с пользователем
  const [hasInteracted, setHasInteracted] = useState(false);
  const [audioContext, setAudioContext] = useState(null);

  // Обработчик для принятия данных из родительского сайта
  useEffect(() => {

    const handleMessage = (e) => {
      if (e.data.userData) {
        console.log(e.data.userData);
        e.data.userData.username = e.data.userData.username.replace("+", "");
        localStorage.setItem("userData", JSON.stringify(e.data.userData));
        dispatch(tokenVerify({ token: e.data.userData.token }))
          .then((result) => {
            if (!result.payload.success) {
              console.error(`Ошибка: ${result.payload.message}`);
              window.location.replace("https://grohe.promo/")
            }
          });
        dispatch(setUserData(e.data.userData));
        dispatch(fetchUserData({ username: e.data.userData.username }));
      }
    }

    // const tempToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLmNvbSIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUub3JnIiwiaWF0IjoxNzM0OTUyMjM0LCJleHAiOjE3MzQ5NTU4MzQsInVzZXJJZCI6Iis3Nzc3MzQxODM5NiJ9.5r_BNgABhLkJ5_-q1VvYjkQUk8ygrYGLoLCKl4kjwvI"

    // dispatch(tokenVerify({
    //   token: tempToken,
    //   username: "77773418396"
    // })).then((result) => {
    //   if (!result.payload.success) {
    //     console.error(`Ошибка: ${result.payload.message}`);
    //     window.location.replace("https://grohe.promo/")
    //   }
    // });


    // dispatch(setUserData({
    //   token: tempToken,
    //   username: "77773418396"
    // }));

    // dispatch(fetchUserData({ username: "77773418396" }));

    // Добавляем слушатель событий
    window.addEventListener("message", handleMessage);

    // Очищаем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // Разрешаем воспроизведение звука по клику
  const handleUserInteraction = () => {
    if (!hasInteracted) {
      // Инициализация AudioContext после пользовательского взаимодействия
      const context = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(context);
      
      if (sound.settings.music) {
        // Инициализация Howl с объектом звука
        if (!musicRef.current) {
          musicRef.current = new Howl(sound.music);
        }
        musicRef.current.play();
        console.log(musicRef)
        setHasInteracted(true); // Ставим флаг, чтобы не воспроизводить звук снова
      }

    } else if (audioContext.state === "suspended") {
      // В случае, если AudioContext приостановлен, восстанавливаем его
      audioContext.resume();
    }
  };

  // Управление музыкой
  useEffect(() => {
    if (!musicSetting) {
      // Останавливаем музыку и очищаем объект
      if (musicRef.current) {
        console.log(musicRef.current)
        musicRef.current.mute(!musicRef.current.muted);
        // musicRef.current = null;
      }
    } else if (musicSetting && musicRef.current) {
      // Создаём новый объект музыки и воспроизводим
      console.log(musicRef.current)
      musicRef.current.mute(false);
    }
  }, [musicSetting, sound.music]);

  return (
    <div className="App e-flex_col e-flex_jb" onClick={() => handleUserInteraction()}>
      <Header />
      <Layout />
      <Footer />
    </div>
  );
}

export default App;