import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import '../../css/transitions.css'

// Pages
import Home from '../MainPage'
import LevelsPage from '../LevelsPage'
import GamePage from '../GamePage'
import WinPage from '../WinPage'
import StatsPage from "../StatsPage";
import SettingsPage from "../SettingsPage";
import LearnPage from "../LearnPage";

const Layout = () => {
  const location = useLocation();

  return (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/map" element={<LevelsPage />} />
          <Route path="/map/:mode" element={<LevelsPage />} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/game/:id" element={<GamePage />} />
          <Route path="/win" element={<WinPage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/learn" element={<LearnPage />} />
        </Routes>
  )
}

export default Layout;