import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/App.css';
import MenuButton from './elements/MenuButton';
import { useDispatch, useSelector } from 'react-redux';
import { setWin, setLevel } from '../store/store';
import { checkPromo } from '../store/thunk/fetchData';
import { Howl } from 'howler';

const WinPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const level = useSelector(state => state.main.game.level);
    const win = useSelector(state => state.main.game.win);
    const timer = useSelector(state => state.main.game.timer);
    const winSound = useSelector(state => state.sound.win);
    const [promo, setPromo] = useState(false);

    
    const calcTime = (timer) => {
        const minutes = Math.floor((timer % 3600000) / 60000); // Минуты
        const seconds = Math.floor((timer % 60000) / 1000); // Секунды
        const ms = Math.floor((timer % 1000) / 10); // Килосекунды
        
        // Форматирование с добавлением ведущих нулей
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        const formattedMs = String(ms).padStart(2, '0');
        
        return `${formattedMinutes}:${formattedSeconds}:${formattedMs}`;
    };
    
    const checkPromocode = () => {
        dispatch(checkPromo())
        .then((data) => {
            if (data.payload.data.status == "success") {
                setPromo(data.payload.data.sale);
            }
        });
    };
    
    useEffect(() => {
        if (win) {
            // Музыка победы
            const music = new Howl(winSound);
            checkPromocode();
        }
    }, [win]);

    return (
        <div className={`WinPage ${(win) ? "fade-in" : ""} e-flex e-flex_col`}>
            <div className='e-flex e-flex_col e-flex_jb card'>
                <h2>Победа!</h2>
                <p>{calcTime(timer)}</p>
                <div className='e-grid'>
                    {
                        (!promo) && <MenuButton title={">>>"} onClick={() => {
                            dispatch(setWin(false));
                            dispatch(setLevel(level + 1));
                            navigate("/game/" + Number(+level + 1));
                            setPromo(false);
                        }} />
                    }
                    {
                        (promo) && (
                            <>
                                <p>Вы выиграли промокод на скидку {promo}!</p>
                                <div className='e-flex e-flex_jb'>
                                    <MenuButton title={"ОТКРЫТЬ"} onClick={() => {
                                        window.location.href = "https://grohe.promo?p=lk";
                                    }} />
                                    <MenuButton title={">>>"} onClick={() => {
                                        dispatch(setWin(false));
                                        dispatch(setLevel(level + 1));
                                        setPromo(false);
                                        navigate("/game/" + Number(+level + 1));
                                    }} />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default WinPage;