import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchStats } from "../store/thunk/fetchData";
import TableItem from "./elements/TableItem";

const StatsPage = () => {

    const dispatch = useDispatch();
    const [stats, setStats] = useState(null);

    useEffect(() => {
        if (!stats) {
            dispatch(fetchStats())
                .then((top) => {
                    setStats(top.payload.data);
                });
        } else {
            console.log("STATS!")
            console.log(stats)
        }
    }, [])

    useEffect(() => {
        console.log(`Stats updated!`)
        console.log(stats)
    }, [stats])

    return (
        <div className="e-flex e-flex_col StatsPage">
            <h2>Статистика</h2>
            <div className="container stats">
                <table>
                    <thead>
                        <td>#</td>
                        <td>Имя</td>
                        {/* <td>Номер</td> */}
                        <td>Пройдено уровней</td>
                        <td>Среднее время</td>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(stats) && stats.map((user, i) => {
                                return (
                                    <>
                                        <TableItem id={i} user={user} key={i} />
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default StatsPage;