import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Howl } from "howler";

const MenuButton = (props) => {

    const clickSound = useSelector(state => state.sound.button)
    const sound = new Howl(clickSound);

    const handleClick = (event) => {
        event.stopPropagation();
        if (props.onClick) {
            props.onClick();
        }
        sound.play();
    }

    return (!props.link) ? (<div className="menu-button"><button className="" onClick={handleClick}>{props.title}</button></div>) :
    (<div className="menu-button"><Link className="" onClick={handleClick} to={props.link}>{props.title}<br /><span>{props.desc}</span></Link></div>)
}

export default MenuButton;